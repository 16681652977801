import React, { useEffect } from 'react';
import { Client } from '@stomp/stompjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, styled } from '@mui/material';
import { setSelectedAccessProductInfo } from '../../redux/modules/accesses';
import { setSubscription } from '../../redux/modules/app';
import Filters from './Filters';

import InstalledApplication from './InstalledApplication';

const StyledHeaderContentApps = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const StyledInstalledApplications = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1),
    minHeight: 146,
    overflow: 'auto',
}));

const HeaderComponent = ({
    setSelectedAccessProductInfoFn,
    selectedProductUuid,
    selectedAccessProductInfo,
    appsList,
    stompClient,
    subscription,
    setSubscriptionFn,
}) => {
    useEffect(() => {
        if (subscription !== null) {
            subscription.unsubscribe();
        }

        const newSubscription = stompClient.subscribe(`/topic/${selectedProductUuid}`, (message) => {
            setSelectedAccessProductInfoFn(JSON.parse(message.body));
        });

        setSubscriptionFn(newSubscription);
        // eslint-disable-next-line
    }, [selectedProductUuid]);

    const renderSelectedProductApps = () => {
        if (appsList.length === 0 || selectedAccessProductInfo === null) {
            return null;
        }

        return selectedAccessProductInfo.apps
            .filter((app) => appsList.some((installedApp) => installedApp.id === app.appId))
            .sort((a, b) => {
                const appA = appsList.find((app) => app.id === a.appId);
                const appB = appsList.find((app) => app.id === b.appId);
                return appA.name.localeCompare(appB.name);
            })
            .map((productApp) => {
                const appsListapp = appsList.find((app) => app.id === productApp.appId);
                appsListapp.versionNumber = productApp.versionNumber; // forward the version number

                return (
                    <InstalledApplication
                        data-testid="installed-application"
                        key={productApp.appId}
                        state={productApp.state}
                        versionUuid={productApp.id}
                        app={appsListapp}
                    />
                );
            });
    };

    return (
        <StyledHeaderContentApps>
            <StyledInstalledApplications>
                <Grid container spacing={1} data-testid="installed-application-grid">
                    {renderSelectedProductApps()}
                </Grid>
            </StyledInstalledApplications>
            <Filters />
        </StyledHeaderContentApps>
    );
};

HeaderComponent.propTypes = {
    setSelectedAccessProductInfoFn: PropTypes.func.isRequired,
    selectedAccessProductInfo: PropTypes.object,
    selectedProductUuid: PropTypes.string.isRequired,
    appsList: PropTypes.arrayOf(PropTypes.object),
    stompClient: PropTypes.instanceOf(Client),
    subscription: PropTypes.object,
    setSubscriptionFn: PropTypes.func.isRequired,
};

HeaderComponent.defaultProps = {
    selectedAccessProductInfo: null,
    appsList: [],
    stompClient: null,
    subscription: null,
};

export default connect(
    (state) => ({
        selectedAccessProductInfo: state.accesses.selectedAccessProductInfo,
        selectedProductUuid: state.accesses.selectedProductUuid,
        appsList: state.apps.list,
        stompClient: state.app.stompClient,
        subscription: state.app.subscription,
    }),
    (dispatch) => ({
        setSelectedAccessProductInfoFn: (info) => dispatch(setSelectedAccessProductInfo(info)),
        setSubscriptionFn: (subscription) => dispatch(setSubscription(subscription)),
    })
)(HeaderComponent);
