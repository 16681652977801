/**
 * Parses a version string into its main parts and suffix number
 * @param {string} version the version to parse
 * @returns {Object} an object containing the main parts of the version and the suffix number
 */
const parseVersion = (version) => {
    const [main, suffix] = version.split('-');
    const mainParts = main.split('.').map(Number);
    // removes all non-digit characters from the suffix string
    const suffixNumber = suffix ? parseInt(suffix.replace(/\D/g, ''), 10) : 0;
    return { mainParts, suffixNumber };
};

/**
 * Compares two versions
 * @param {string} version1 the first version to compare
 * @param {string} version2 the second version to compare
 * @returns
 */
const compareVersions = (version1, version2) => {
    const v1 = parseVersion(version1);
    const v2 = parseVersion(version2);

    for (let i = 0; i < 3; i += 1) {
        const num1 = v1.mainParts[i] || 0;
        const num2 = v2.mainParts[i] || 0;

        if (num1 > num2) return 1;
        if (num1 < num2) return -1;
    }

    if (v1.suffixNumber > v2.suffixNumber) return 1;
    if (v1.suffixNumber < v2.suffixNumber) return -1;

    return 0;
};

/**
 * Sorts the available versions of an app regarding the version number
 * @param {Array<Object>} versionsArray
 * @returns {Array<Object>} the sorted array of versions
 */
const sortAvailableVersions = (versionsArray) => {
    return versionsArray.sort((a, b) => compareVersions(b.versionNumber, a.versionNumber));
};

export { compareVersions, sortAvailableVersions };
